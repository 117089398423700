<template>
  <nav :class="['nav', { 'sticky-nav': isScrolled }]" class="">
    <div class="wrapper px-4 md:px-12 flex flex-row justify-between items-end md:items-center">
      <a :href="url">
        <img :src="logo" class="max-w-[38px] md:max-w-[62px]" alt="Wallin logo" />
      </a>
      <div class="flex flex-row gap-4 items-center">
        <div class="flex flex-row gap-2 md:gap-4 md:pr-4">
          <a href="https://www.erstapenthouse.se/" target="_blank" class="md:mr-4">
            <button class="btn">penthouse</button>
          </a>
          <a href="https://www.ersta25.se/intresseanmalan">
            <button class="btn">INTRESSEANMÄLAN</button>
          </a>
        </div>
        <button class="focus:outline-none" @click="toggleMenu">
          <template v-if="!menuExpanded">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="33"
              viewBox="0 0 37 33"
              fill="none"
            >
              <line y1="32" x2="37" y2="32" stroke="black" stroke-width="2" />
              <line y1="17" x2="37" y2="17" stroke="black" stroke-width="2" />
              <line y1="1" x2="37" y2="1" stroke="black" stroke-width="2" />
            </svg>
          </template>
          <template v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="33"
              viewBox="0 0 37 33"
              fill="none"
            >
              <line x1="0" y1="0" x2="37" y2="33" stroke="black" stroke-width="2" />
              <line x1="0" y1="33" x2="37" y2="0" stroke="black" stroke-width="2" />
            </svg>
          </template>
        </button>
        <div
          class="fixed top-[82px] md:top-[109px] left-0 bg-beige w-full z-10 mega-menu overflow-hidden"
          :style="{ height: menuHeight }"
          :class="{ 'border-t-[3px] border-black': menuExpanded }"
        >
          <div v-for="(link, i) in links" :key="i" class="heading-md w-full">
            <div
              v-if="link.child_links"
              class="flex flex-col md:flex-row items-start"
              :class="{ 'bg-beige-200': isActive }"
              @click="toggleContent"
            >
              <div
                class="w-full border-black hover:bg-beige-200"
                :class="{ 'md:w-1/2 border-b bg-white md:bg-beige-200': isActive }"
              >
                <div
                  class="border-b-black flex flex-row wrapper"
                  :class="{ 'justify-end': isActive }"
                >
                  <p
                    class="col-span-1 py-4 w-full max-w-[720px] h-auto cursor-pointer px-5 text-black"
                  >
                    {{ link.link.title }}
                  </p>
                </div>
              </div>
              <ul
                v-if="link.child_links"
                class="bg-beige-200 md:bg-beige w-full md:w-1/2 children col-span-1 border-b border-black"
                :class="{ 'block md:border-none': isActive }"
              >
                <li
                  v-for="(child, index) in link.child_links"
                  :key="index"
                  class="pl-4 border-b border-black flex flex-row justify-start py-4 bg-beige-200 hover:bg-white text-black"
                >
                  <a :href="child.lank.url" class="w-full max-w-[720px] px-5 md:px-0">
                    {{ child.lank.title }}
                  </a>
                </li>
              </ul>
            </div>
            <a
              v-else
              :href="link.link.url"
              class="border-t border-black py-4 w-full block hover:bg-beige-200"
            >
              <p class="wrapper px-5 text-black">{{ link.link.title }}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  props: ['links', 'logo', 'url'],
  data() {
    return {
      menuHeight: '0',
      menuExpanded: false,
      isActive: false,
      isScrolled: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    toggleMenu() {
      this.menuExpanded = !this.menuExpanded
      this.menuHeight = this.menuExpanded ? '100vh' : '0'
      document.body.classList.toggle('overflow-hidden', this.menuExpanded)
    },
    toggleContent() {
      this.isActive = !this.isActive
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 0
    },
  },
}
</script>

<style>
.nav {
  transition:
    background-color 0.3s,
    padding 0.3s;
  @apply w-full py-6 md:py-[35px] w-full z-10 fixed border-b-[3px] border-black;
}
.sticky-nav {
  @apply bg-beige;
  padding: 12px;
}
.mega-menu {
  top: 120px;
}

.children {
  display: none;
}
.children li:last-child {
  border: none;
}
</style>
